import { useEffect, useState } from "react";
import {useTranslation} from "@i18n";
import SingleQuest from "./components/SingleQuest/SingleQuest";
import stl from "./FAQ.module.css";
import {createInternalUrlWithParams} from "@/utils/common";
import {useLang} from "@/common/Contexts/LanguageContext";

const FAQ = ({ faqs, faqsPage, isHomePage }) => {
  const { t } = useTranslation();
  const lang = useLang();
  const [activeQustIndex, setActiveQustIndex] = useState(0);

  const setNewIndex = (i) => {
    if (isHomePage) {
      setActiveQustIndex(i);
      return;
    }
    setActiveQustIndex((pre) => (pre === i ? -1 : i));
  };

  useEffect(() => {
    if (isHomePage) return;
    setActiveQustIndex(0);
  }, []);

  return (
    <>
      <section
        className={`${stl.faqContainer} ${isHomePage ? stl.homePage : ""}`}
      >
        <div className="container">
          {isHomePage && (
            <h3 className={["big", "centRev", stl.hdrT].join(" ")}>
              {t("اسئلة متكررة", { ns: "web_common" })}
            </h3>
          )}
          <div className={stl.allFAQCont}>
            {faqs &&
              faqs.length > 0 &&
              faqs.map((item, i) => {
                return (
                  <SingleQuest
                    key={i}
                    onClick={() => {
                      setNewIndex(i);
                    }}
                    qust={item.question}
                    ans={item.answer}
                    showAns={activeQustIndex === i}
                    faqsPage={faqsPage}
                  />
                );
              })}
          </div>
          {isHomePage && (
            <a href={createInternalUrlWithParams('/faq',{},lang)}>
              <span className={`${stl.moreFaq} ${stl.whtBtn} m-hide`}>
                {t("اعرض المزيد", { ns: "web_common" })}
              </span>
              <span className={`m-show ${stl.mobLink}`}>
                {t("المزيد من الاسئلة المتكررة", { ns: "web_common" })}
              </span>
            </a>
          )}
        </div>
      </section>
    </>
  );
};

export default FAQ;
